import { Injectable } from '@angular/core';
import { generate } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TftcGenService {



  thanks = []
  constructor() {

    this.generate();
  }




  //Tack så mycket för gömman
  //Loggad tillsammans med NNNN.
  //Tack CO för denna

  //Cache symonumer

  cache = ["gömman", "cachen", "geocachen",];
  cache2 = ["gömma", "cache", "geocache"];

  //adjektiv

  addj = [
    "den kluriga",
    "den smarta",
    "den finurliga",
    "den fina",
    "den unika",
    " den trevliga",
    "den finurliga",
    "den knepiga",
    "den fiffiga",
    "den listiga",
    "den luriga",
    "den sluga",
    "den invecklade",
    "den komplicerade",
    "den besvärliga",
    "den påhittiga",
    "den kreativa"
  ];

  addj2 = [
    "en klurig",
    "en smart",
    "en finurlig",
    "en fin",
    "en unik",
    "en trevlig",
    "en knepig",
    "en fiffig",
    "en listig",
    "en lurig",
    "en slug",
    "en invecklad",
    "en komplicerad",
    "en besvärlig",
    "en påhittig",
    "en kreativ"
  ];




  sent1() {

    let types = [
      "Idag var jag ute på en promenad.",



    ]

  }

  generate() {

    //addj före tack
    let addjBeforeThanks = ["Stort"];

    this.thanks = [];

    for (let index = 0; index < 100; index++) {
      let thank = "";

      thank +=
        this.getNonOptiol(["Tack ", "Tackar "]) +
        this.getOptional([" så     mycket ", " så jättemycket "]) +
        " för " +
        this.getOptional(this.addj) +
        " " +
        this.getNonOptiol(this.cache) +
        ".";
      this.thanks.push(thank);
    }

    for (let i = 0; i < this.thanks.length; i++) {
      let thank = this.thanks[i];
      this.thanks[i] = this.removeDoubleSpace(thank);



    }


    this.thanks.forEach(thank => {

      thank = this.removeDoubleSpace(thank);
      //console.log(this.removeDoubleSpace(thank));
      //  console.log("");
    });
    //console.log(this.thanks)
  }
  getNonOptiol(words: string[]) {
    const randomElement = words[Math.floor(Math.random() * words.length)];
    return randomElement;
  }


  removeDoubleSpace(text) {
    let cleaned = "";
    let prev = "";

    for (let c of text) {
      if (c !== " " || prev !== " ") cleaned += c;
      prev = c;
    }

    return cleaned;
  }

  optional(value, chance) {
    if (!chance) chance = 0.5;
    if (Math.random() < chance) return "";
    return value;
  }



  getOptional(words, chance?) {
    if (!chance) chance = 0.5;

    if (Math.random() < chance) return "";

    const randomElement = words[Math.floor(Math.random() * words.length)];
    return randomElement;
  }

}
