import { Component } from '@angular/core';
import { TftcGenService } from './tftc-gen.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'tftc-gen';

correct_pass="wembley2";

password="";

isAuth=false;
constructor(){

  let b=window.localStorage.getItem('tftcpass');
  this.password=b;

  if(this.password===this.correct_pass) this.isAuth=true;

}

login(){
if(this.password===this.correct_pass) {
  this.isAuth=true;
  window.localStorage.setItem('tftcpass', this.password);
}
  //console.log(this.password===this.correct_pass)
}

 
}
