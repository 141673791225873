
    <style>
      body {
          background-color: #02947e;
     
          margin: 0;
      }

      .center-me {
          display: flex;
          justify-content: center;
          align-items: center;

          height: 90vh;
      }
  </style>

<div *ngIf="!isAuth" class="center-me">
  <input type="password" name="" id="" [(ngModel)]="password">
  <button (click)="login()">Logga in</button>
</div>


<app-generator *ngIf="isAuth"></app-generator>