<style>
    .cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    }
  
    .card {
      /* border: 3px solid rgb(124, 112, 58);; */
      /* flex: 1; */
      margin: 10px;
      /* min-height: 50px;
      min-width: 150px; */
      padding: 20px;
      flex-grow: 1;
      cursor: pointer;
      position: relative;
      border-radius: 5px;
      background-color: rgb(189, 170, 88);
      transition: 200ms;
  
  
      -webkit-box-shadow: 5px 5px 19px -10px rgba(0, 0, 0, 0.64);
      -moz-box-shadow: 5px 5px 19px -10px rgba(0, 0, 0, 0.64);
      box-shadow: 5px 5px 19px -10px rgba(0, 0, 0, 0.64);
    }
  
    .card:hover {
      transform: scale(1.1);
      z-index: 100;
      -webkit-box-shadow: 10px 10px 19px -10px rgba(0, 0, 0, 0.64);
      -moz-box-shadow: 10px 10px 19px -10px rgba(0, 0, 0, 0.64);
      box-shadow: 10px 10px 19px -10px rgba(0, 0, 0, 0.64);
  
      -webkit-box-shadow: 5px 5px 37px -7px rgba(0, 0, 0, 1);
      -moz-box-shadow: 5px 5px 37px -7px rgba(0, 0, 0, 1);
      box-shadow: 5px 5px 37px -7px rgba(0, 0, 0, 1);
    }
  
    .copied {
      background-color: lightblue;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      text-align: center;
      opacity: 0;
    }
  </style>
  
  <div class="cards">
    <div class="card" *ngFor="let thank of gen.thanks" (click)="copyMessage(thank)">
      {{thank}}
  
  
      <div class="copied">Kopierat!</div>
    </div>
  </div>